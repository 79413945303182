var log = console.log;
console.log = function () {
  var args = Array.prototype.slice.call(arguments);
  args.unshift("🔵 [" + Date.now() + "] — ");
  log.apply(console, args);
};

const CC = {};
window.CC = CC;
CC.version = "4.0.34";

CC.CONST = {
  // LS_ (LS = localstorage)
  LS_REPORTSADD_RESULTS_LABEL: "cc_reports-add_results",
  LS_REPORTSADD_RESULT_SELECTED_LABEL: "cc_reports-add_result-select",
  LS_REPORTSADD_UI_EXTRAINFO_PANEL: "cc_ui_reports-add_openclose-panel",
  LS_ME_AUTH_SMS_CODE_SENT_AT: "cc_me_auth_sms_code_sent_at",
  LS_ME_AUTH_EMAIL_CODE_SENT_AT: "cc_me_auth_email_code_sent_at",
  LS_ME_ENTITY_SERVICE_REPORT_DRAFT: "cc_me_entity_[[id]]_service_report_draft",
  LS_ME_REPORTS_SENT: "cc_me_reports_sent",
  LS_ME_PERSONAL_ADDRESSBOOK: "cc_last_addressbook",
  LS_ME_CURRENT_CLIENT_CREDENTIALS: "cc_current_client_credentials",
  LS_ME_CURRENT_REPORT_DRAFT_ID: "cc_current_report_draft_id",
  LS_ME_CURRENT_IMPORT_CREDENTIALS: "cc_current_client_import_credentials",
  LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS:
    "cc_me_entity_[[id]]_service_report_current_validations",
};

CC.COMMONS = {
  init: function () {
    /**
     * CC.COMMONS.init()
     */
    // init clientUUID
    CC.SERVICES.reports.fn.unsignedUserValidation.initClientUUID();
  },
  log: {
    prefix: "🔵 — ",
  },
};

CC.DATA = {
  logPrefix: "💾 ",
  store: {
    reset: function () {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("cc_")) {
          delete localStorage[key];
        }
      });
    },
    set: function (label, value) {
      console.log(
        CC.DATA.logPrefix + "store label: " + label + ", with value: " + value
      );
      localStorage.setItem(label, JSON.stringify(value));
    },
    get: function (label) {
      var obj = localStorage.getItem(label);
      if (obj) {
        value = JSON.parse(obj);
        console.log(
          CC.DATA.logPrefix + "get label: " + label + ", with value: " + value
        );
      } else {
        value = null;
        console.log(
          CC.DATA.logPrefix + "get label: " + label + ", no result. Return null"
        );
      }

      return value;
    },
    append: function (label, additionalValue) {
      value = JSON.parse(localStorage.getItem(label));
      if (value == null) {
        // first time
        value = new Array();
      }
      if (value instanceof Array) {
        value.push(additionalValue);
      }
      localStorage.setItem(label, JSON.stringify(value));
    },
    remove: function (label) {
      console.log(CC.DATA.logPrefix + "remove label: " + label);
      localStorage.removeItem(label);
    },
  },
};

CC.UI = {
  me: {
    init: function (assetPath) {
      // paste into digits
      $(".input_digits").bind("paste", function (e) {
        var pastedData = e.originalEvent.clipboardData.getData("text");
        $("#input_digits-1").val(pastedData[0]);
        $("#input_digits-2").val(pastedData[1]);
        $("#input_digits-3").val(pastedData[2]);
        $("#input_digits-4").val(pastedData[3]);
        $("#input_digits-5").val(pastedData[4]);
        $("#input_digits-6").val(pastedData[5]);

        $(".input_digits").removeClass("has-error");
      });

      // handle inputs
      $(".input_digits").on("keyup", function (e) {
        if ((e.shiftKey && e.keyCode == 9) || e.keyCode == 9) {
          return;
        }

        var currentId = $(this).attr("id").replace("input_digits-", "");
        var currentValue = $(this).val();

        if (currentValue.length >= 1) {
          var nextId = parseInt(currentId) + 1;
          if (nextId <= 6) {
            if (e.which != 8 && isNaN(String.fromCharCode(e.which))) {
              // it's a number
              // if (e.value.toString().length == 1) {}
            } else {
              $("#input_digits-" + nextId).trigger("focus");
            }
          }
        }

        // handle UI
        $(this).removeClass("has-error");
      });

      // autosize textarea
      var tas = document.querySelectorAll("textarea.autosize");
      tas.forEach(function (item, index) {
        // - restore autosize of focus and
        autosize(item); // - active the autosize
        item.addEventListener("focus", function () {
          // move the cursor at the end of
          autosize.update(item); // textarea
          item.value += " ";
        });
      });

      // set up the textarea counter
      $("textarea.counter").keyup(function (e) {
        var counterDivId = $(this).data("counter_id");
        var maxLenght = $(this).data("chars_max_length");
        var counterDiv = $("#" + counterDivId);
        var currentTextareaLenght = $(this).val().length;
        var text = currentTextareaLenght + "/" + maxLenght;
        counterDiv.text(text);
      });

      // add form group effects
      $(
        ".input-group > input, .input-group-icon > input, .search-container .search-field, .search-container .search-button"
      )
        .focus(function (e) {
          $(this).parent().addClass("input-group-focus");
        })
        .blur(function (e) {
          $(this).parent().removeClass("input-group-focus");
        });

      // loader
      $(".cc-animated-loader").each(function () {
        var currentID = $(this).attr("id");
        bodymovin.loadAnimation({
          container: document.getElementById(currentID),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: assetPath + "/data/loader/data.json",
        });
      });
      $(".cc-animated-map-loader").each(function () {
        var currentID = $(this).attr("id");
        bodymovin.loadAnimation({
          container: document.getElementById(currentID),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: assetPath + "/data/map-loader/data.json",
        });
      });
    },
    errors: {
      afterValidationError: {
        reset: function () {
          /**
           * Example:
           * CC.UI.me.errors.afterValidationError.reset();
           */
          $(".validation-error").addClass("d-none");
        },
        pinNotValid: function () {
          /**
           * Called when a user send a bad pin and the api refuse it
           *
           * Example:
           * CC.UI.me.errors.afterValidationError.pinNotValid();
           */
          $("#validation-error-codenotvalid").removeClass("d-none");
        },
      },
      unsignedUser: {
        creationError: function () {
          /**
           * Example:
           * CC.UI.me.errors.unsignedUser.creationError();
           */
          // handle internal UI
          $(".error-modal-img").addClass("d-none");
          $("#error-creation-img").removeClass("d-none");

          // show modal
          $(".modal").modal("hide");
          $("#errorModal").modal("show");
        },
        validationError: function () {
          /**
           * Example:
           * CC.UI.me.errors.unsignedUser.validationError();
           */
          // handle internal UI
          $(".error-modal-img").addClass("d-none");
          $("#error-validation-img").removeClass("d-none");

          // show modal
          $(".modal").modal("hide");
          $("#errorModal").modal("show");
        },
      },
      sendReportError: function () {
        /**
         * CC.UI.me.errors.sendReportError();
         */
        $(".after-error").removeClass("d-none");
        $(".after-confirm").addClass("d-none");
        $(".before-confirm").addClass("d-none");

        $("#report-send-button").addClass("d-none");
        $("#report-error-button").removeClass("d-none");
        $("#confirmSend .close").prop("disabled", false);

        $("#confirmSend").on("hidden.bs.modal", function () {
          $(".before-confirm").removeClass("d-none");
          $(".after-error").addClass("d-none");
          $(".after-confirm").addClass("d-none");
          $("#report-error-button").addClass("d-none");
          $("#report-send-button .send").removeClass("d-none");
          $("#report-send-button .wait").addClass("d-none");
          $("#report-send-button")
            .prop("disabled", false)
            .removeClass("d-none");
        });
      },
      genericError: function () {
        /**
         * CC.UI.me.errors.genericError();
         */
        // handle internal UI
        $(".error-modal-img").addClass("d-none");
        $("#error-generic-img").removeClass("d-none");

        // show modal
        $(".modal").modal("hide");
        $("#errorModal").modal("show");
      },
      genericNetworkError: function () {
        /**
         * CC.UI.me.errors.genericNetworkError();
         */
        $(".modal").modal("hide");
        $("#networkErrorModal").modal("show");
      },
    },
  },
  bs4: {
    tooltip: {
      enable: function () {
        $(function () {
          $('[data-toggle="tooltip"]:not(.tooltip-disabled)').tooltip({
            template:
              '<div class="tooltip" role="tooltip"><div class="arrow tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
          });
        });
      },
    },
    popover: {
      hideAll: function () {
        $(".popover").popover("hide");
        $(".popover-ready").popover("hide");
      },
    },
  },
  a11y: {
    init: function () {
      $(".clck")
        .attr("tabindex", "0") // Add tab indexes
        .keypress(function (evt) {
          var key = evt.key;
          evt.preventDefault();
          if (key === " " || key === "Enter") {
            evt.currentTarget.click();
          }
        });

      var bindableObjects = ["latitude", "longitude"];
      for (var i = 0; i < bindableObjects.length; i++) {
        objectId = bindableObjects[i];
        $("#" + objectId).change(function () {
          var $value = $(this).val();
          var $id = $(this).attr("id");
          console.log(
            "The element with id: " + $id + " change its value to: " + $value
          );
        });
      }
    },
  },
};

CC.EVENTS = {};

CC.SERVICES = {
  users: {
    me: {
      ui: {
        resetBeforeChanges: function () {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.ui.resetBeforeChanges();
           */
          // reset digits
          $(".input_digits").val("").parent().removeClass("has-error");

          // reset field(s) error
          $(".user_email-field").parent().removeClass("has-error");
          $("#user_tos_privacy").parent().removeClass("has-error");

          // reset validation error(s)
          CC.UI.me.errors.afterValidationError.reset();
        },
        showImportLoader: function (kind) {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.ui.showImportLoader();
           */
          if (kind === undefined) {
            kind = "SEARCH";
          }
          $(".reports-list-empty-container").addClass("d-none");
          $(".reports-list-loader-container").removeClass("d-none");

          if (kind == "SEARCH") {
            $("#loader-search-img").removeClass("d-none");
            $("#loader-search").removeClass("d-none");
          }
          if (kind == "IMPORT") {
            $("#loader-search-img").addClass("d-none");
            $("#loader-import-img").removeClass("d-none");
            $("#loader-import").removeClass("d-none");
          }
        },
        hideImportLoader: function () {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.ui.hideImportLoader();
           */
          // $("#loader").html("");
          $(".reports-list-empty-container").removeClass("d-none");
          $(".reports-list-loader-container").addClass("d-none");

          $("#loader-search").addClass("d-none");
          $("#loader-search-img").addClass("d-none");
        },
        showValidation: function () {
          /**
           *
           * Mostra l'inserimento del pin
           *
           * Example:
           * CC.SERVICES.users.me.ui.showValidation();
           */

          $("#validateImport").modal("show");
          $("#validation-code-wrapper").removeClass("d-none");

          // set up re-send code UI
          $(".validation-code-resend-container").removeClass("d-none");
          CC.SERVICES.generic.countdown(
            "2:00",
            "#countdown-timer",
            function () {
              // handle UI
              $(".countdown-wrapper").addClass("d-none");
              $(".validation-code-resend-wrapper").removeClass("d-none");
            }
          );
        },
      },
      events: {
        onServiceStart: function () {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.events.onServiceStart();
           */
          $(document).ready(function () {
            // email search button
            $(".user_email_search-button").click(function () {
              CC.SERVICES.users.me.events.onClickCheckImportReports(
                function () {
                  // success
                  CC.SERVICES.users.me.events.onClickImportReports();
                },
                function (err) {
                  // error
                  CC.UI.me.errors.genericError();
                }
              );
            });

            // email
            $(".user_email-field").keypress(function (event) {
              if (event.key == "Enter") {
                CC.SERVICES.users.me.events.onClickImportReports();
              }
            });

            // validate user
            $("#validate-user-button").on("click", function () {
              CC.SERVICES.users.me.events.onClickImportReportsAfterValidation();
            });
          });
        },
        onClickResetLocalData: function () {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.events.onClickResetLocalData()
           *
           */
          $("#confirm-data-deleted").removeClass("d-none");
          CC.DATA.store.reset();
        },
        onClickCheckImportReports: function (
          email,
          successCallback,
          errorCallback
        ) {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.events.onClickCheckImportReports()
           *
           *
           */
          var userEmail = $(".user_email-field").val();
          var userAgreement = $("#user_tos_privacy").is(":checked");

          // check email format ...
          var isValid =
            CC.SERVICES.reports.events.onUpdateCheckEmailFormat(userEmail);
          if (!isValid) {
            $(".user_email-field").parent().addClass("has-error");
            return;
          }

          if (!userAgreement) {
            $("#user_tos_privacy").parent().addClass("has-error");
            return;
          }

          // UI: show loader and digits ...
          CC.SERVICES.users.me.ui.showImportLoader();

          // TODO:
          // im here
          CC.SERVICES.reports.fn.unsignedUserValidationByEmail.reports_available_count(
            userEmail,
            function (data) {
              if (data["response"] && data["response"]["success"] == false) {
                $("#user_tos_privacy").parent().removeClass("has-error");
                $(".user_email-field").parent().removeClass("has-error");
                CC.SERVICES.users.me.ui.hideImportLoader();

                $("#noReportsModal").modal("show");
              } else {
                CC.SERVICES.users.me.events.onClickImportReports();
              }
            },
            function (err) {
              $("#user_tos_privacy").parent().removeClass("has-error");
              $(".user_email-field").parent().removeClass("has-error");
              CC.SERVICES.users.me.ui.hideImportLoader();

              CC.UI.me.errors.genericNetworkError();
            }
          );
        },
        onClickImportReports: function () {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.events.onClickImportReports()
           *
           */
          var entityId = null;
          var userEmail = $(".user_email-field").val();
          var userAgreement = $("#user_tos_privacy").is(":checked");
          var legalAgreementAt = new Date()
            .toISOString()
            .replace("T", " ")
            .replace("Z", "");

          // reset UI
          CC.SERVICES.users.me.ui.resetBeforeChanges();

          // check email format ...
          var isValid =
            CC.SERVICES.reports.events.onUpdateCheckEmailFormat(userEmail);
          if (!isValid) {
            $(".user_email-field").parent().addClass("has-error");
            return;
          }

          if (!userAgreement) {
            $("#user_tos_privacy").parent().addClass("has-error");
            return;
          }

          // UI: show loader and digits ...
          CC.SERVICES.users.me.ui.showImportLoader();

          // validate credentials and show validation modal
          CC.SERVICES.reports.fn.unsignedUserValidationByEmail.create(
            userEmail,
            legalAgreementAt,
            entityId,
            "GET_REPORTS",
            function (res) {
              // UI: hide loader ...
              CC.SERVICES.users.me.ui.hideImportLoader();

              if (res["response"]["success"] === true) {
                // get credentials ...
                credentials = {
                  email: userEmail,
                  credential_uuid:
                    res["response"]["response"]["credential_uuid"],
                  credential_expired_at:
                    res["response"]["response"]["expired_at"],
                };

                // store into localstorage ...
                $("#user_credential-field").val(credentials["credential_uuid"]);

                // otp any time
                CC.SERVICES.users.me.ui.showValidation("PIN");
              } else {
                CC.UI.me.errors.genericError();
              }
            },
            function (err) {
              CC.SERVICES.users.me.ui.hideImportLoader();
              CC.UI.me.errors.genericNetworkError();
            }
          );
        },
        onClickImportReportsAfterValidation: function (
          userEmail,
          userCredential
        ) {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.events.onClickImportReportsAfterValidation()
           *
           */
          // init variables
          var digits = new Array();
          var userEmail = $(".user_email-field").val();
          var userCredential = $("#user_credential-field").val();

          var credentials = {
            credential: userEmail,
            credential_uuid: userCredential,
            credential_type: "EMAIL",
            pin: null,
          };

          // show Loader
          CC.SERVICES.users.me.ui.showImportLoader();

          // get digits
          var digit_1 = $("#input_digits-1").val();
          var digit_2 = $("#input_digits-2").val();
          var digit_3 = $("#input_digits-3").val();
          var digit_4 = $("#input_digits-4").val();
          var digit_5 = $("#input_digits-5").val();
          var digit_6 = $("#input_digits-6").val();

          // store digits into array
          digits.push(digit_1);
          digits.push(digit_2);
          digits.push(digit_3);
          digits.push(digit_4);
          digits.push(digit_5);
          digits.push(digit_6);
          digits = digits.join("").toString();
          credentials["pin"] = digits;

          // handle UI
          $("#validate-user-button").attr("disabled", "disabled");

          // send data
          CC.SERVICES.reports.fn.unsignedUserValidationByEmail.validate(
            credentials["credential"],
            credentials["credential_uuid"],
            credentials["pin"],
            function (response) {
              // remove loader
              CC.SERVICES.users.me.ui.hideImportLoader();

              // if is valid, start importing data ...
              if (response["is_valid"] === true) {
                // Import Reports and handle UI
                var userEmail = credentials["credential"];
                var credentialUUID = credentials["credential_uuid"];

                // show loader again and remove modals...
                $(".modal").modal("hide");
                CC.SERVICES.users.me.ui.showImportLoader("IMPORT");

                CC.SERVICES.reports.fn.unsignedUserReports.import(
                  userEmail,
                  credentialUUID,
                  function (data) {
                    if (data["response"] && data["response"]["success"]) {
                      var reports = data["response"]["response"]["reports"];

                      // handle UI
                      CC.SERVICES.users.me.ui.hideImportLoader();

                      // render Report(s)
                      CC.SERVICES.users.me.fn.renderReports(reports);
                    } else {
                      CC.UI.me.errors.genericError();
                    }
                  },
                  function (err) {
                    CC.UI.me.errors.genericError();

                    // handle UI
                    CC.SERVICES.users.me.ui.hideImportLoader();
                  }
                );
              } else {
                // ensure the showvalidation is ok
                CC.SERVICES.reports.ui.showSteps.showValidation("PIN");
              }
            },
            function () {
              // handle UI
              CC.SERVICES.users.me.ui.hideImportLoader();

              // error callback
              // CC.SERVICES.reports.ui.showSteps.showValidation("PIN");
              CC.UI.me.errors.genericNetworkError();
            }
          );
        },
      },
      fn: {
        renderReports: function (reports) {
          /**
           *
           * Example:
           * CC.SERVICES.users.me.fn.renderReports(reports)
           */
          var html = "";
          var reportsHtml = [];

          for (let index = 0; index < reports.length; index++) {
            const report = reports[index];

            // report id & title
            var r_hexa_id = report["hexa_id"];
            var r_uuid =
              report["uuid"].split("-")[0] +
              report["hexa_id"].replace("#", "-");
            var r_title = report["title"] || report["location"]["address"];

            // notification(s)
            var r_is_email_notifications_enabled = false;
            var r_is_push_notifications_enabled = false;

            // description
            var r_description = report["description"];

            // address
            var r_address = null;
            var r_address_latitude = null;
            var r_address_longitude = null;
            if (report["location"]) {
              r_address_latitude = report["location"]["latitude"];
              r_address_longitude = report["location"]["longitude"];
              r_address = report["location"]["address"];
            }

            // attachment(s)
            var r_attachment_background_url = null;
            var r_attachments_count = report["sharable_attachments"]["count"];

            // answer(s)
            var r_answers = report["answers"];
            var r_answers_count = 0;

            // type(s)
            var r_type_main_label = report["type"]["main"];
            var r_type_sub_label = report["type"]["sub"];
            var r_type_id = report["type"]["id"];
            var r_status_id = report["status"]["id"];

            // entity
            var r_entity_name = report["entity"]["name"];
            var r_entity_logo_url = report["entity"]["logos"]["small"];

            // reporter
            var r_reporter_firstname = report["reporter"]["first_name"];
            var r_reporter_lastname = report["reporter"]["last_name"];
            var r_reporter_email = report["reporter"]["email"];
            var r_reporter_phone = report["reporter"]["phone"];

            // date(s)
            var r_created_at = report["created_at"]["extra_formatted"]["short"];
            var r_created_at_detail =
              report["created_at"]["extra_formatted"]["extra_long"];

            // start creating html to inject
            var reportMock = $("#report-wrapper-mock").html();

            // title & id
            reportMock = reportMock.replaceAll("[[[ r_hexa_id ]]]", r_hexa_id);
            reportMock = reportMock.replaceAll("[[[ r_uuid ]]]", r_uuid);
            reportMock = reportMock.replaceAll("[[[ r_title ]]]", r_title);

            // description
            if (r_description) {
              reportMock = reportMock.replaceAll(
                "[[[ r_description ]]]",
                r_description
              );
              reportMock = reportMock.replaceAll(
                "report-description-optional",
                ""
              );
            } else {
              reportMock = reportMock.replaceAll("[[[ r_description ]]]", "");
            }

            // address
            if (r_address_latitude != "0.0" && r_address_longitude != "0.0") {
              var reportMapImg =
                "/rest/private/maps/" +
                r_address_latitude +
                "," +
                r_address_longitude;
              var reportBackgroundUrlStyle =
                "background-image: url('/rest/private/maps/" +
                r_address_latitude +
                "," +
                r_address_longitude +
                "')";
              reportMock = reportMock.replaceAll(
                "[[[ r_map_background_url ]]]",
                reportBackgroundUrlStyle
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_map_img ]]]",
                reportMapImg
              );
              reportMock = reportMock.replaceAll("report-map-optional", "");
              reportMock = reportMock.replaceAll(
                "[[[ r_address ]]]",
                r_address
              );
              reportMock = reportMock.replaceAll("report-address-optional", "");
            } else {
              reportMock = reportMock.replaceAll(
                "[[[ r_map_background_url ]]]",
                ""
              );
              reportMock = reportMock.replaceAll("[[[ r_map_img ]]]", "");
              reportMock = reportMock.replaceAll("[[[ r_address ]]]", "");
            }

            // attachment(s)
            if (r_attachments_count > 0) {
              var attachmentImg = report["sharable_attachments"]["list"][0];
              if (attachmentImg.includes(".pdf")) {
                var attachmentBackgroundUrlStyle = "";
              } else {
                var attachmentBackgroundUrlStyle =
                  "background-image: url('" +
                  report["sharable_attachments"]["list"][0] +
                  "')";
                reportMock = reportMock.replaceAll("report-attachment-pdf", "");
              }
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_background_url ]]]",
                attachmentBackgroundUrlStyle
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_img ]]]",
                attachmentImg
              );
              reportMock = reportMock.replaceAll(
                "[[[ report_attachments_count ]]]",
                r_attachments_count
              );
              reportMock = reportMock.replaceAll(
                "report-attachments-optional",
                ""
              );
              reportMock = reportMock.replaceAll(
                "report-attachment-optional",
                ""
              );

              var attachmentImg2 = report["sharable_attachments"]["list"][1];
              if (attachmentImg2) {
                if (attachmentImg2.includes(".pdf")) {
                  var attachmentBackgroundUrlStyle2 = "";
                } else {
                  var attachmentBackgroundUrlStyle2 =
                    "background-image: url('" +
                    report["sharable_attachments"]["list"][1] +
                    "')";
                  reportMock = reportMock.replaceAll(
                    "report-attachment2-pdf",
                    ""
                  );
                }
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_background_url_2 ]]]",
                  attachmentBackgroundUrlStyle2
                );
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_img_2 ]]]",
                  attachmentImg2
                );
                reportMock = reportMock.replaceAll(
                  "report-attachment2-optional",
                  ""
                );
              } else {
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_background_url_2 ]]]",
                  ""
                );
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_img_2 ]]]",
                  ""
                );
              }

              var attachmentImg3 = report["sharable_attachments"]["list"][2];
              if (attachmentImg3) {
                if (attachmentImg3.includes(".pdf")) {
                  var attachmentBackgroundUrlStyle3 = "";
                } else {
                  var attachmentBackgroundUrlStyle3 =
                    "background-image: url('" +
                    report["sharable_attachments"]["list"][2] +
                    "')";
                  reportMock = reportMock.replaceAll(
                    "report-attachment3-pdf",
                    ""
                  );
                }
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_background_url_3 ]]]",
                  attachmentBackgroundUrlStyle3
                );
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_img_3 ]]]",
                  attachmentImg3
                );
                reportMock = reportMock.replaceAll(
                  "report-attachment3-optional",
                  ""
                );
              } else {
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_background_url_3 ]]]",
                  ""
                );
                reportMock = reportMock.replaceAll(
                  "[[[ r_attachment_img_3 ]]]",
                  ""
                );
              }
            } else {
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_background_url ]]]",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_img ]]]",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_background_url_2 ]]]",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_img_2 ]]]",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_background_url_3 ]]]",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_attachment_img_3 ]]]",
                ""
              );
            }
            reportMock = reportMock.replaceAll(
              "[[[ report_attachments_count ]]]",
              r_attachments_count
            );

            // type(s)
            var typeUrl = report["type"]["icons"]["small"];
            reportMock = reportMock.replaceAll(
              "[[[ r_type_main_label ]]]",
              r_type_main_label
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_type_sub_label ]]]",
              r_type_sub_label
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_type_img_url ]]]",
              typeUrl
            );

            // status
            var availableStatuses = [2, 3, 4, "fallback"];
            for (let index = 0; index < availableStatuses.length; index++) {
              const el = availableStatuses[index];
              if (r_status_id == el) {
                reportMock = reportMock.replaceAll(
                  "status-" + r_status_id + "-optional",
                  ""
                );
              }
            }

            // entity
            reportMock = reportMock.replaceAll(
              "[[[ r_entity_name ]]]",
              r_entity_name
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_entity_logo_url ]]]",
              r_entity_logo_url
            );

            // date(s)
            reportMock = reportMock.replaceAll(
              "[[[ r_created_at ]]]",
              r_created_at
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_created_at_detail ]]]",
              r_created_at_detail
            );

            // ***************************************
            // *********** Detail Modals *************
            // ***************************************

            // answers
            var answersHtml = new Array();
            r_answers_count = r_answers.length;
            var answerMockHtml = `
              <div class="report-update">
                <div class="update-text">
                  [[[ r_update_text ]]]
                </div>
                <small class="update-date">
                    [[[ r_update_created_at ]]]
                </small>
              </div>`;
            if (r_answers_count > 0) {
              for (let index = 0; index < r_answers.length; index++) {
                const element = r_answers[index];
                var text = element["text"];
                var created_at = element["created_at"];

                if (text && created_at) {
                  var html = answerMockHtml.replaceAll(
                    "[[[ r_update_text ]]]",
                    text
                  );
                  html = html.replaceAll(
                    "[[[ r_update_created_at ]]]",
                    created_at["formatted"]
                  );
                  answersHtml.push(html);
                }
              }
              reportMock = reportMock.replaceAll(
                "[[[ r_updates ]]]",
                answersHtml.join("")
              );
              reportMock = reportMock.replaceAll(
                "report-updates-optional-container",
                ""
              );
            } else {
              // nothing to do
            }
            reportMock = reportMock.replaceAll(
              "[[[ r_answers_count ]]]",
              r_answers_count
            );

            // reporter
            reportMock = reportMock.replaceAll(
              "[[[ r_reporter_firstname ]]]",
              r_reporter_firstname
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_reporter_lastname ]]]",
              r_reporter_lastname
            );
            reportMock = reportMock.replaceAll(
              "[[[ r_reporter_email ]]]",
              r_reporter_email
            );
            if (r_reporter_phone) {
              reportMock = reportMock.replaceAll(
                "report-reporter-phone-value-optional",
                ""
              );
              reportMock = reportMock.replaceAll(
                "[[[ r_reporter_phone ]]]",
                r_reporter_phone
              );
            }

            // push html
            reportsHtml.push(reportMock);
          }

          // inject html
          html = reportsHtml.join("");
          $("#reports-list-container").html(html);

          // handle UI
          $("#reports-list-alert-info").removeClass("d-none");
          $(".reports-list-empty-container").addClass("d-none");
          $(".search-reports-button").removeClass("d-none");
        },
      },
    },
  },
  reports: {
    const: {
      reportSteps: {
        eventNames: {
          SELECT_MAIN_CATEGORY: {
            id: 0,
            name: "SELECT_MAIN_CATEGORY",
            stepCount: 1,
          },
          SELECT_SUB_CATEGORY: {
            id: 2,
            name: "SELECT_SUB_CATEGORY",
            stepCount: 2,
          },
          AFTER_SUGGESTION: {
            id: 3,
            name: "AFTER_SUGGESTION",
            stepCount: 3,
          },
          AFTER_MAP: {
            id: 4,
            name: "AFTER_MAP",
            stepCount: 4,
          },
          AFTER_CONTACTS: {
            id: 5,
            name: "AFTER_CONTACTS",
            stepCount: 5,
          },
          AFTER_VALIDATION: {
            id: 6,
            name: "AFTER_VALIDATION",
            stepCount: 6,
          },
          BEFORE_SEND: {
            id: 7,
            name: "BEFORE_SEND",
            stepCount: 7,
          },
        },
      },
    },
    ui: {
      showSteps: {
        showCategories: function () {
          /**
           * Example:
           * CC.SERVICES.reports.ui.showSteps.showCategories();
           */
          CC.SERVICES.reports.ui.resetBeforeChanges();

          $("#step-maintypes").removeClass("d-none");
          $(".back-button").addClass("d-none");
        },
        showTypes: function () {
          /**
           * Example:
           * CC.SERVICES.reports.ui.showSteps.showTypes();
           */
          CC.SERVICES.reports.ui.resetBeforeChanges();

          $("#step-subtypes").removeClass("d-none");
        },
        showDetails: function () {
          /**
           * Example:
           * CC.SERVICES.reports.ui.showSteps.showDetails();
           */
          CC.SERVICES.reports.ui.resetBeforeChanges();

          $("#step-issue-details").removeClass("d-none");
        },
        showContacts: function () {
          /**
           * Example:
           * CC.SERVICES.reports.ui.showSteps.showContacts();
           */
          CC.SERVICES.reports.ui.resetBeforeChanges();
          CC.SERVICES.reports.ui.resetPrivacyAndTos();

          $("#step-contact-details").removeClass("d-none");
        },
        showValidation: function (kind) {
          /**
           * Example:
           * CC.SERVICES.reports.ui.showSteps.showValidation()
           */

          $("#step-recap-details").addClass("d-none");

          if (kind == "PIN") {
            /**
             * CC.SERVICES.reports.ui.showSteps.showValidation()
             */
            $("#step-contact-details").addClass("d-none");
            $("#step-validate-unsigned-user").removeClass("d-none");

            $("#validation-loader-wrapper").addClass("d-none");
            $("#validation-code-wrapper").removeClass("d-none");

            $("#validate-user-button").removeAttr("disabled");

            // set up re-send code UI
            $(".validation-code-resend-container").removeClass("d-none");
            CC.SERVICES.generic.countdown(
              "2:00",
              "#countdown-timer",
              function () {
                // handle UI
                $(".countdown-wrapper").addClass("d-none");
                $(".validation-code-resend-wrapper").removeClass("d-none");
              }
            );
          } else if (kind == "SKIP") {
            CC.SERVICES.reports.ui.showSteps.showSummaryStep();
          } else {
            console.warning("not implemented yet");
          }
        },
        showSummaryStep: function () {
          /**
           * CC.SERVICES.reports.ui.showSteps.showSummaryStep()
           */
          CC.SERVICES.reports.ui.resetBeforeChanges();

          // update resume
          var currentReportId = CC.DATA.store.get(
            CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID
          );
          var currentEntityId = parseInt($("#current_entity_id").val());
          var reportDraftlsLabel =
            CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
              "[[id]]",
              currentEntityId
            ) +
            "_" +
            currentReportId;
          var currentReportDraft = CC.DATA.store.get(reportDraftlsLabel);

          // title
          $(".report-title-value").html(currentReportDraft.title);
          if (currentReportDraft.title) {
            $(".report-title-value").removeClass("d-none");
          }
          $(".report-description-value").html(currentReportDraft.description);

          // categories
          $(".report-maintype-value").html(
            currentReportDraft.___main_type_label
          );
          $(".report-type-value").html(currentReportDraft.___type_label);

          // address
          if (currentReportDraft.___is_location_required) {
            $(".report-address-value").html(currentReportDraft.address);
            $(".report-address").removeClass("d-none");
          } else {
            $(".report-address").addClass("d-none");
          }

          // update user information
          $(".report-reporter-firstname-value").html(
            currentReportDraft.user_firstname
          );
          $(".report-reporter-lastname-value").html(
            currentReportDraft.user_lastname
          );
          $(".report-reporter-email-value").html(currentReportDraft.user_email);

          // phone handler UI
          $(".report-reporter-phone-value").html(currentReportDraft.phone);
          if (currentReportDraft.phone && currentReportDraft.phone.length > 0) {
            $(".report-reporter-phone-value").removeClass("d-none");
          } else {
            $(".report-reporter-phone-value").addClass("d-none");
          }
          // if (currentReportDraft.___is_phone_required || currentReportDraft.phone) {
          //   $(".report-reporter-phone-value").html(currentReportDraft.phone);
          //   $(".report-reporter-phone-value").removeClass("d-none");
          // } else {
          //   $(".report-reporter-phone-value").addClass("d-none");
          // }

          // alert(currentReportDraft.attachments);
          if (currentReportDraft.attachments) {
            var splittedAttachments = currentReportDraft.attachments.split(";");
            $("#attachments-count").html(splittedAttachments.length);
          } else {
            $("#attachments-count").html("0");
          }

          $("#step-recap-details").removeClass("d-none");

          // buttons
          $("#confirm-report-send-button").removeAttr("disabled", "disabled");
        },
      },
      confirmDraftDeleteModal: {
        open: function (lsId) {
          /**
           * Example:
           * CC.SERVICES.reports.ui.confirmDraftDeleteModal.open();
           */
          $("#confirm-delete-button").attr("data-lsId", lsId);
          $("#confirmDelete").modal("show");
        },
        confirm: function () {
          /**
           * Example:
           * CC.SERVICES.reports.ui.confirmDraftDeleteModal.confirm();
           */
          var lsId = $("#confirm-delete-button").attr("data-lsId");
          if (lsId) {
            $("#confirm-delete-button").removeAttr("data-lsId");
            localStorage.removeItem(lsId);
            $("#draft-" + lsId).remove();
          }

          location.href = location.pathname;
        },
      },
      resetPrivacyAndTos: function () {
        // reset tos and privacy all the time
        $("#user_tos_privacy").prop("checked", false);
        $("#confirm-user-button").attr("disabled", "disabled");
      },
      resetPinValidation: function () {
        // reset core fields to prevent pre-fill
        $("#input_digits-1").val("");
        $("#input_digits-2").val("");
        $("#input_digits-3").val("");
        $("#input_digits-4").val("");
        $("#input_digits-5").val("");
        $("#input_digits-6").val("");
      },
      resetBeforeChanges: function () {
        /**
         * Questo metodo normalizza le cose tra un passaggio e l'altro.
         * Resetta tutto a d-none per poi lasciare all'event manager scegliere cosa si deve
         * vedere e cosa non si deve vedere.
         * Inoltre, questo metodo resetta anche i campi che vogliamo vengano compilati ogni
         * volta, come il pin di attivazione e l'accettazione dei tos
         *
         * Example:
         * CC.SERVICES.reports.ui.resetBeforeChanges()
         */
        console.log("CC.SERVICES.reports.ui.resetBeforeChanges() called");

        // hide all modals
        $(".modal").modal("hide");

        // hide all alerts
        $(".validation-error").addClass("d-none");
        $(".input_digits").removeClass("has-error");

        // show back button and delete button
        $(".aside-buttons-wrapper").removeClass("d-none");
        $(".back-button").removeClass("d-none");
        $("#current-report-save-button").removeClass("d-none");
        $("#current-report-remove-button").removeClass("d-none");
        $(".aside-buttons").removeClass("d-none");

        // timer
        $(".validation-code-resend-container").addClass("d-none");
        $(".validation-code-resend-wrapper").addClass("d-none");
        $("#countdown-timer").html("");

        // collapsable elements
        $("#drafts-container").collapse("hide");
        // step loading
        $("#step-loading").removeClass("d-none");
        // reset Main Types
        $("#step-maintypes").addClass("d-none");
        // reset Sub Types
        $("#step-subtypes").addClass("d-none");
        // reset Location
        $("#step-issue-details").addClass("d-none");
        // reset Description and Attachments
        $("#step-contact-details").addClass("d-none");
        // step validation
        $("#step-validate-unsigned-user").addClass("d-none");
        // reset Recap
        $("#step-recap-details").addClass("d-none");
        // reset Messageback
        $("#step-messageback").addClass("d-none");
        // step validation
        $("#step-validate-unsigned-user").addClass("d-none");
        // disable send button
        $("#confirm-report-send-button").attr("disabled", "disabled");

        // campi non sempre obbligatori che lo diventano in base a dove si sta segnalando
        if (CC.SERVICES.reports.data.reportDraft["___is_phone_required"]) {
          $("#user_phone-country-wrapper").addClass("required");
        } else {
          $("#user_phone-country-wrapper").removeClass("required");
        }

        // send button
        $("#confirm-report-send-button").attr("disabled", "disabled");

        // remove loading
        $("#step-loading").removeClass("d-none");
      },
    },
    data: {
      reportDraft: {
        // temp variables
        ___created_at: null,
        ___main_type_id: null,
        ___main_type_label: "",
        ___type_label: "",
        ___is_location_required: true,
        ___is_suggestion_required: false,
        ___is_phone_required: false,
        ___is_validation_required: true,
        ___step_current: null,
        ___step_last_complete: null,
        ___attachments: [],
        // generic
        entity_id: null,
        device_id: null,
        created_by_user_type_id: null,
        // report
        // - type
        type_id: null,
        // - position
        address: null,
        latitude: null,
        longitude: null,
        aal1: null,
        aal2: null,
        aal3: null,
        locality: null,
        postal_code: null,
        country: null,
        // coords: null,
        // - description\
        title: null,
        description: null,
        // - attachments
        attachments: null,
        // - phone
        phone: null,
        // - user contacts
        user_firstname: null,
        user_lastname: null,
        user_email: null,
        user_tos_privacy: null,
        credential_uuids: null,
      },
    },
    events: {
      // CC.SERVICES.reports.events
      onResume: function (lsId) {
        /**
         * Probabilmente sara' necessario entityId come parametro
         */
        CC.SERVICES.reports.data.reportDraft = JSON.parse(
          localStorage.getItem(lsId)
        );

        // resume hack
        var address = CC.SERVICES.reports.data.reportDraft["address"];
        var mainTypeId =
          CC.SERVICES.reports.data.reportDraft["___main_type_id"];
        var typeId = CC.SERVICES.reports.data.reportDraft["type_id"];
        var description = CC.SERVICES.reports.data.reportDraft["description"];
        var title = CC.SERVICES.reports.data.reportDraft["title"];

        if (address) {
          $("#address-infobox").html(
            CC.SERVICES.reports.data.reportDraft["address"]
          );
          $("#confirm-map-button").removeAttr("disabled");
          $("#map-container").addClass("map-active");
        }

        // maintype UI
        var mainTypeElement = $("#step-maintypes-element-" + mainTypeId);
        mainTypeElement.addClass("selected");
        mainTypeElement.parent().prepend(mainTypeElement);

        // type UI
        var typeElement = $("#type-id-" + typeId);
        typeElement.addClass("selected");
        typeElement.parent().prepend(typeElement);

        // additionals UI
        $("#description").val(description);
        $("#title").val(title);
        if (title) {
          if (title.length > 0) {
            $(".page-header-wrapper")
              .find(".report-title")
              .removeClass("d-none")
              .text(title);
            $(".page-header-wrapper").find(".default-title").addClass("d-none");
          } else {
            $(".page-header-wrapper")
              .find(".report-title")
              .text("")
              .addClass("d-none");
            $(".page-header-wrapper")
              .find(".default-title")
              .removeClass("d-none");
          }
        }
      },
      onServiceStart: function (entityId) {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onServiceStart()
         *
         */
        var LOGPREFIX = "CC.SERVICES.reports.events.onServiceStart() -> ";
        var uuid;
        try {
          uuid = crypto.randomUUID();
        } catch (error) {
          if (error instanceof TypeError) {
            uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
              (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
              ).toString(16)
            );
          }
        }
        var reportClientId = uuid.split("-")[0];
        var isRestoreCalled = false;
        var isDraftAvailable = false;
        var availableDrafts = new Array();

        // handle resuming as first
        isRestoreCalled = CC.SERVICES.reports.fn.isRestoreCalled();
        if (isRestoreCalled) {
          //
          // ***********************************************
          // ************       Restore         ************
          // ***********************************************
          //
          var lsId = isRestoreCalled;
          var reportClientId = lsId.split("_").reverse()[0];

          // create a pointer to this draft and store data into html
          CC.DATA.store.set(
            CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID,
            reportClientId
          );
          $("#current_ls_report_id").val(reportClientId);

          // ✍️ A bit of log here ...
          console.log(
            LOGPREFIX +
              "⛵ {restore} report into entityId: " +
              entityId +
              ", with 🆔: " +
              reportClientId +
              " ..."
          );
          console.log(
            LOGPREFIX +
              "📄 " +
              availableDrafts.length +
              " draft(s) available ..."
          );

          console.log(
            LOGPREFIX +
              "------------------- resuming start ---------------------"
          );
          CC.SERVICES.reports.events.onResume(lsId);
        } else {
          // create a pointer to this draft and store data into html
          CC.DATA.store.set(
            CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID,
            reportClientId
          );
          $("#current_ls_report_id").val(reportClientId);

          // reset map history and previous researches
          var storeLabelPosition1 = CC.CONST.LS_REPORTSADD_RESULTS_LABEL;
          var storeLabelPosition2 =
            CC.CONST.LS_REPORTSADD_RESULT_SELECTED_LABEL;
          CC.DATA.store.remove(storeLabelPosition1);
          CC.DATA.store.remove(storeLabelPosition2);

          // ✍️ A bit of log here ...
          console.log(
            LOGPREFIX +
              "✅ {new} report into entityId: " +
              entityId +
              ", with 🆔: " +
              reportClientId +
              " ..."
          );
          console.log(
            LOGPREFIX +
              "📄 " +
              availableDrafts.length +
              " draft(s) available ..."
          );
        }

        // handle draft stuff
        availableDrafts = CC.SERVICES.reports.fn.getAvailableDrafts(entityId);
        if (availableDrafts.length > 0) {
          isDraftAvailable = true;

          if (availableDrafts.length > 0) {
            $("#draft-button").removeClass("d-none");
            $("#draft-button-badge")
              .removeClass("d-none")
              .text(availableDrafts.length);

            // html handle
            for (i = 0; i < availableDrafts.length; i++) {
              var draft = availableDrafts[i]["obj"];
              var localStorageId = availableDrafts[i]["id"];
              var reportId = localStorageId.split("_").reverse()[0];
              var reportMainType = draft["___main_type_label"];
              var reportMainTypeId = draft["___main_type_id"];
              var reportType = draft["___type_label"];
              if (reportType) {
                var reportLabel = reportMainType + ", " + reportType;
              } else {
                var reportLabel = reportMainType;
              }
              var reportTitle = draft["title"];

              var html = $("#draft-example").clone();
              html.removeAttr("id").attr("id", "draft-" + reportId);
              html.attr("data-lsId", localStorageId);
              html.removeClass("d-none");

              // remove closure binding
              (function (lsId, repId) {
                html.find(".draft-remove-button").click(function () {
                  CC.SERVICES.reports.events.onClickDeleteDraft(lsId);

                  var currentBadgeText = $("#draft-button-badge").text();
                  if (Number.isInteger(parseInt(currentBadgeText))) {
                    var newValue = parseInt(currentBadgeText) - 1;
                    if (newValue > 0) {
                      $("#draft-button-badge").text(newValue);
                    } else {
                      $("#draft-button-badge").remove();
                      $("#draft-button").remove();
                      $("#drafts-container").remove();
                    }
                  } else {
                    // remove the broken badge
                    $("#draft-button-badge").remove();
                  }
                });
              })(localStorageId, reportId);

              // resume closure binding
              (function (repId) {
                html.find(".draft-resume-button").click(function () {
                  location.href = location.pathname + "?id=" + repId;
                });
              })(reportId);

              if (reportLabel) {
                html.find(".element-name").text(reportLabel.trim());
              }
              if (reportTitle) {
                html.find(".element-name-title").text(reportTitle.trim());
              }

              // icon
              html
                .find(".draft-category-img")
                .find("img")
                .attr(
                  "src",
                  "https://s3-eu-west-1.amazonaws.com/static.comuni-chiamo.com/assets/shared/types/v3.1/" +
                    reportMainTypeId +
                    ".svg"
                );

              $("#drafts-list").append(html);
            }
            $("#draft-example").remove();

            var mainTypeLabel =
              availableDrafts[0]["obj"]["___main_type_label"].trim();
            var typeLabel = availableDrafts[0]["obj"]["___type_label"].trim();
            $("#last-draft-maintype-label").html(mainTypeLabel);
            $("#last-draft-type-label").html(typeLabel);
          }
        }

        // ************************************************************
        // ************************* binding **************************
        // ************************************************************
        $(document).ready(function () {
          // Title and Description
          if (!isRestoreCalled) {
            $("#title").val("");
            $("#description").val("");
          }

          // Captcha
          $("#not-a-bot").val("");

          // Back button
          $(".back-button").on("click", function () {
            CC.SERVICES.reports.events.onClickBack();
          });

          // Pin validation
          $("#validate-user-button").on("click", function () {
            CC.SERVICES.reports.events.onClickNext("AFTER_VALIDATION");
          });
          // Send again button
          $("#send-validation-code-again-button").on("click", function () {
            // send code again
            var entityId = parseInt($("#current_entity_id").val());
            var userEmail = $("#user_email-field").val().trim();
            var legalAgreementAt = new Date()
              .toISOString()
              .replace("T", " ")
              .replace("Z", "");
            CC.SERVICES.reports.fn.unsignedUserValidationByEmail.create(
              userEmail,
              legalAgreementAt,
              entityId,
              "NEW_REPORT",
              function () {},
              function () {}
            );

            // handle UI
            $("#send-validation-code-again-button").addClass("d-none");
            $(".validation-code-last-tip").removeClass("d-none");
          });

          // Delete current Report
          $("#current-report-remove-button").on("click", function () {
            var currentReportId = CC.DATA.store.get(
              CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID
            );
            var currentEntityId = parseInt($("#current_entity_id").val());
            var reportDraftlsLabel =
              CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
                "[[id]]",
                currentEntityId
              ) +
              "_" +
              currentReportId;

            // call delete draft method and let it handle the rest ...
            CC.SERVICES.reports.events.onClickDeleteDraft(reportDraftlsLabel);
          });

          // Send Report
          $("#report-send-button").on("click", function () {
            var captchaNotValid = function () {
              $(".captcha-result-wrapper").addClass("has-error");
              $("#wrong-result").removeClass("d-none");
              $(".modal").modal("hide");
            };
            var captchaValid = function () {
              $(".captcha-result-wrapper").removeClass("has-error");
              $("#wrong-result").addClass("d-none");
            };
            // handle captcha as first thing
            try {
              var captcha = parseInt($("#not-a-bot").val());
              var match = parseInt($("#not-a-bot").attr("data-plus"));
            } catch (err) {
              captchaNotValid();
              return;
            }
            if (captcha != match) {
              captchaNotValid();
              return;
            } else {
              captchaValid();
            }

            // handle UI
            $(".after-confirm").removeClass("d-none");
            $(".before-confirm").addClass("d-none");
            $("#report-send-button .wait").removeClass("d-none");
            $("#report-send-button .send").addClass("d-none");
            $("#confirmSend .close").prop("disabled", true);
            $(this).prop("disabled", true);

            CC.SERVICES.reports.events.onClickSendUnsignedReport(
              function (data) {
                if (data["response"]["success"] == true) {
                  //
                  // 1. delete draft
                  // 2. move the draft into a dictionary who store all the reports
                  // 3. store message back into the same dict as above
                  // 4. redirect to thank you page
                  // 5. ...
                  //
                  var currentReportId = CC.DATA.store.get(
                    CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID
                  );
                  var currentEntityId = parseInt($("#current_entity_id").val());
                  var reportDraftlsLabel =
                    CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
                      "[[id]]",
                      currentEntityId
                    ) +
                    "_" +
                    currentReportId;
                  var reportsSentlsLabel = CC.CONST.LS_ME_REPORTS_SENT;
                  var currentReportDraft =
                    CC.DATA.store.get(reportDraftlsLabel);

                  // setup variable(s) for redirect
                  var redirectUrl = null;
                  var splittedUrl = window.location.href.split("/");

                  // setup variable(s) for handle messageback and store data localy
                  var reportId = data["response"]["response"]["report"]["id"];
                  var reportHexaId =
                    data["response"]["response"]["report"]["hexa_id"];
                  var reportLocalId = currentEntityId;
                  var reportUUID =
                    data["response"]["response"]["report"]["uuid"];
                  var reportUUIDShort = reportUUID.split("-")[0];

                  // delete old draft and the pointer
                  CC.DATA.store.remove(reportDraftlsLabel);
                  CC.DATA.store.remove(CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID);

                  // store new report
                  var currentStoredReportsSent =
                    CC.DATA.store.get(reportsSentlsLabel);
                  if (!currentStoredReportsSent) {
                    currentStoredReportsSent = [];
                  }
                  currentStoredReportsSent.push({
                    report: data["response"]["response"]["report"],
                    messageback: data["response"]["response"]["messageback"],
                    ids: {
                      report_ids: {
                        hexa_id: reportHexaId,
                        id: reportId,
                        local_id: currentEntityId,
                        uuid: reportUUID,
                      },
                    },
                    version: "0.0.1",
                  });
                  CC.DATA.store.set(
                    reportsSentlsLabel,
                    currentStoredReportsSent
                  );

                  // 4. calculate and redirect to the right page ...
                  redirectUrl =
                    "/entities/redirect/" +
                    currentEntityId +
                    "/thankyou/" +
                    reportUUIDShort;

                  // redirect
                  location.href = redirectUrl;
                } else {
                  CC.UI.me.errors.sendReportError();
                }
              },
              function () {
                CC.UI.me.errors.sendReportError();
              }
            );
          });

          // Draft(s)
          if (isDraftAvailable) {
            $("#draft-button").click(function () {
              $("#drafts-container").collapse("toggle");
            });
          }

          if (
            availableDrafts.length == 1 &&
            CC.SERVICES.reports.data.reportDraft.___step_current ==
              CC.SERVICES.reports.const.reportSteps.eventNames
                .SELECT_MAIN_CATEGORY.name
          ) {
            $("#drafts-container").collapse("show");
          }

          // Maintype(s): binding onClickNext() function on Maintype element(s)
          $(".step-maintypes-element").each(function () {
            $(this).on("click", function (e) {
              // the click is binding on a label, we need to stop propagation
              e.preventDefault();

              // get the Id and call the method
              var typeId = $(this).data("type_id");
              CC.SERVICES.reports.events.onClickNext(
                "SELECT_MAIN_CATEGORY",
                typeId.toString()
              );
            });
          });

          // Subtype(s): binding onClickNext() function on subtype element(s)
          $(".step-subtypes-element").each(function () {
            $(this).on("click", function (e) {
              // the click is binding on a label, we need to stop propagation
              e.preventDefault();

              // get the Id and call the method
              var typeId = $(this).data("type_id");
              if (
                $(this)
                  .data("type-id-additional-values")
                  .indexOf("YES_SUGGESTION") >= 0
              ) {
                $("#modal-alerttypes-" + typeId).modal("show");
              } else {
                CC.SERVICES.reports.events.onClickNext(
                  "SELECT_SUB_CATEGORY",
                  typeId.toString()
                );
              }
            });
          });

          // latitude
          $("#latitude").on("change load", function () {
            if (
              $("#latitude").val().length + $("#longitude").val().length >=
              3
            ) {
              // worst case
              $("#confirm-map-button").removeAttr("disabled");
            }
          });

          // longitude
          $("#longitude").on("change load", function () {
            if (
              $("#latitude").val().length + $("#longitude").val().length >=
              3
            ) {
              // worst case
              $("#confirm-map-button").removeAttr("disabled");
            }
          });

          // address
          $("#address-infobox").on("DOMSubtreeModified", function () {
            var _address = $("#address-infobox").html();

            if (_address.length > 0) {
              $("#location-field").removeClass("has-error");
            }
          });

          // description
          $("#description").on("keyup", function () {
            var _description = $("#description").val().trim();
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "description",
              _description
            );

            if (_description.length > 0) {
              $("#description-field").removeClass("has-error");
            }
          });

          // title
          $("#title").on("keyup", function () {
            var _title = $("#title").val().trim();
            CC.SERVICES.reports.fn.updateDraft(reportClientId, "title", _title);
            if (_title.length > 0) {
              $(".page-header-wrapper")
                .find(".report-title")
                .removeClass("d-none")
                .text(_title);
              $(".page-header-wrapper")
                .find(".default-title")
                .addClass("d-none");
            } else {
              $(".page-header-wrapper")
                .find(".report-title")
                .text("")
                .addClass("d-none");
              $(".page-header-wrapper")
                .find(".default-title")
                .removeClass("d-none");
            }
          });

          // user email
          $("#user_email-field").on("keyup", function () {
            var entityId = parseInt($("#current_entity_id").val());
            CC.SERVICES.reports.events.onUpdateCheckEmailValidation(entityId);
          });
          $("#user_email-field").on("focus", function () {
            var entityId = parseInt($("#current_entity_id").val());
            CC.SERVICES.reports.events.onUpdateCheckEmailValidation(entityId);
          });

          // inizializzazione selectize numero di telefono
          $(".country-select").selectize({
            // valueField: 'id',                  // The name of the property to use as the value when an item is selected.
            // labelField: 'name',                // The name of the property to render as an option / item label.
            maxItems: 1, // The max number of items the user can select.
            closeAfterSelect: true, // If true, the dropdown will be closed after a selection is made.
            onDropdownClose: function ($dropdown) {
              $($dropdown)
                .find(".selected")
                .not(".active")
                .removeClass("selected");
            },
          });
        });

        // ✍️ A bit of log here ...
        console.log(LOGPREFIX + "page binding events run ...");

        // init draft resource
        CC.SERVICES.reports.fn.updateDraft(
          reportClientId,
          "entity_id",
          parseInt($("#current_entity_id").val())
        );
        CC.SERVICES.reports.fn.updateDraft(reportClientId, "device_id", 1); // WEB
        CC.SERVICES.reports.fn.updateDraft(
          reportClientId,
          "created_by_user_type_id",
          3
        ); // NOT_SIGNED
        CC.SERVICES.reports.fn.updateDraft(
          reportClientId,
          "___step_current",
          CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_MAIN_CATEGORY
            .name
        ); // First step

        // Handle UI
        $("#step-maintypes").removeClass("d-none");
      },
      onClickBack: function () {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onClickBack();
         */
        console.log("CC.SERVICES.reports.ui.onClickBack() called");

        // get data
        var currentReportId = CC.DATA.store.get(
          CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID
        );
        var currentEntityId = parseInt($("#current_entity_id").val());
        var lsLabel =
          CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
            "[[id]]",
            currentEntityId
          ) +
          "_" +
          currentReportId;
        var currentReportData = CC.DATA.store.get(lsLabel);
        var currentStep = currentReportData["___step_current"];

        // handle UI
        CC.SERVICES.reports.ui.resetBeforeChanges();

        // handler event(s)
        if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_MAIN_CATEGORY
            .name
        ) {
          // set step_last_complete to multiple back click(s)
          // nothing to do: it's the first step
        } else if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_SUB_CATEGORY
            .name
        ) {
          $("#step-maintypes").removeClass("d-none");
          CC.SERVICES.reports.ui.showSteps.showCategories();

          // set step_last_complete to multiple back click(s)
          CC.SERVICES.reports.fn.updateDraft(
            currentReportId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames
              .SELECT_MAIN_CATEGORY.name
          );
        } else if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_SUGGESTION.name
        ) {
          $("#step-subtypes").removeClass("d-none");
          $(".step-subtypes-ids").addClass("d-none");
          $(
            "#step-subtypes-" + currentReportData["___main_type_id"]
          ).removeClass("d-none");

          // set step_last_complete to multiple back click(s)
          CC.SERVICES.reports.fn.updateDraft(
            currentReportId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_SUB_CATEGORY
              .name
          );
        } else if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_MAP.name
        ) {
          $("#step-issue-details").removeClass("d-none");

          // set step_last_complete to multiple back click(s)
          CC.SERVICES.reports.fn.updateDraft(
            currentReportId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_SUGGESTION
              .name
          );
        } else if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_CONTACTS.name
        ) {
          $("#step-contact-details").removeClass("d-none");

          // set step_last_complete to multiple back click(s)
          CC.SERVICES.reports.fn.updateDraft(
            currentReportId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_MAP.name
          );
        } else if (
          currentStep ==
          CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_VALIDATION.name
        ) {
          // set step_last_complete to multiple back click(s)
          CC.SERVICES.reports.fn.updateDraft(
            currentReportId,
            "___step_last_complete",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_CONTACTS.name
          );

          $("#step-validate-unsigned-user").removeClass("d-none");
        } else {
          console.warning("this event is not handled yet: " + currentStep);
        }
      },
      onClickNext: function (eventName, eventValues) {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onClickNext();
         */
        var LOGPREFIX = "🎩 - ";
        // debug
        console.debug(LOGPREFIX + "onClickNext() called", {
          eventName: eventName,
          eventValues: eventValues,
        });

        // init shared variables
        var entityId = parseInt($("#current_entity_id").val());
        var reportClientId = $("#current_ls_report_id").val();

        // reset time
        CC.SERVICES.reports.ui.resetBeforeChanges();
        if (eventName != "AFTER_VALIDATION") {
          CC.SERVICES.reports.ui.resetPinValidation();
        }
        if (eventName != "AFTER_CONTACTS") {
          CC.SERVICES.reports.ui.resetPrivacyAndTos();
        }

        // analyze events
        if (eventName == "SELECT_MAIN_CATEGORY") {
          var eventValue = parseInt(eventValues);

          // handle data
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames
              .SELECT_MAIN_CATEGORY.name;
          var currentMainTypeLabel = $("#step-maintypes-element-" + eventValue)
            .find(".element-name")
            .text()
            .trim();

          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___created_at",
            new Date().toISOString().replace("T", " ").replace("Z", "")
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___main_type_id",
            parseInt(eventValue)
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___main_type_label",
            currentMainTypeLabel
          );

          // bind mainTypeId to local variable
          var selectedMainTypeId =
            CC.SERVICES.reports.data.reportDraft.___main_type_id;

          // handle UI
          $(".step-maintypes-element").removeClass("selected");
          $("#step-maintypes-element-" + eventValue).addClass("selected");
          $("#step-maintypes-element-confirm")
            .removeAttr("disabled")
            .removeClass("btn-light")
            .addClass("btn-primary");

          $("#step-maintypes").addClass("d-none");
          $("#step-subtypes").removeClass("d-none");
          $(".step-subtypes-ids").addClass("d-none");
          $("#step-subtypes-" + selectedMainTypeId).removeClass("d-none");

          // handle UI and icon category
          $(".category-img")
            .find("img")
            .attr(
              "src",
              "https://s3-eu-west-1.amazonaws.com/static.comuni-chiamo.com/assets/shared/types/v3.1/" +
                eventValue +
                ".svg"
            );

          // update step data
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_SUB_CATEGORY
              .name
          );
        } else if (eventName == "SELECT_SUB_CATEGORY") {
          // TODO:
          // - capire se c'e' una suggestion
          // - capire se c'e' bisogno della posizione o no
          // - se falso entrambe, passare a description
          var subCategoryId = parseInt(eventValues);
          var subTypeLabel = $("#type-id-" + subCategoryId)
            .find(".element-name")
            .text()
            .trim();
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.SELECT_SUB_CATEGORY
              .name;
          var additionalValues = $("#type-id-" + subCategoryId).data(
            "type-id-additional-values"
          );
          var values = additionalValues.split(";");
          var isLocationRequired = values[0];
          var isSuggestionRequired = values[1];
          var isPhoneRequired = values[2];
          var isValidationRequired = values[3];
          var phone = null;

          console.debug("onClickNext(SELECT_SUB_CATEGORY) called()", {
            subCategoryId: subCategoryId,
            isLocationRequired: isLocationRequired,
            isSuggestionRequired: isSuggestionRequired,
            isPhoneRequired: isPhoneRequired,
            isValidationRequired: isValidationRequired,
          });

          // check `no_location` and save data
          if (isLocationRequired == "NO_LOCATION") {
            CC.SERVICES.reports.data.reportDraft[
              "___is_location_required"
            ] = false;
          } else {
            CC.SERVICES.reports.data.reportDraft[
              "___is_location_required"
            ] = true;
          }

          // check `is_suggestion`, every time check the value
          // to keep sync in everytime the choose of an user
          // some lines below we will save the value into Localstorage too
          if (isSuggestionRequired == "YES_SUGGESTION") {
            CC.SERVICES.reports.data.reportDraft[
              "___is_suggestion_required"
            ] = true;
          } else {
            CC.SERVICES.reports.data.reportDraft[
              "___is_suggestion_required"
            ] = false;
          }

          // check `is_phone_required`, every time check the value
          // to keep sync in everytime the choose of an user
          // some lines below we will save the value into Localstorage too
          if (isPhoneRequired == "YES_PHONE") {
            CC.SERVICES.reports.data.reportDraft["___is_phone_required"] = true;
          } else {
            CC.SERVICES.reports.data.reportDraft[
              "___is_phone_required"
            ] = false;
          }

          // check `no_location` and save data
          if (isValidationRequired == "YES_VALIDATION") {
            CC.SERVICES.reports.data.reportDraft[
              "___is_validation_required"
            ] = true;
          } else {
            CC.SERVICES.reports.data.reportDraft[
              "___is_validation_required"
            ] = false;
          }

          // handle UI
          $(".type-ids").removeClass("selected");
          $("#type-id-" + subCategoryId).addClass("selected");
          $("#step-subtypes-element-confirm")
            .removeAttr("disabled")
            .addClass("btn-primary")
            .removeClass("btn-light");
          $("#step-subtypes").addClass("d-none");
          $("#step-issue-details").removeClass("d-none");

          // TODO: questa parte va controllata
          if (CC.SERVICES.reports.data.reportDraft["___is_location_required"]) {
            // restore map marker if needed
            if (
              CC.SERVICES.reports.data.reportDraft.latitude &&
              CC.SERVICES.reports.data.reportDraft.longitude
            ) {
              // restoring from draft report, set the marker
              if (CC.MAP.marker) {
                CC.MAP.marker.setMap(null);
              }
              CC.MAP.markers.addMarkerOnMap(
                CC.SERVICES.reports.data.reportDraft.latitude,
                CC.SERVICES.reports.data.reportDraft.longitude
              );
              CC.MAP.map.setCenter({
                lat: parseFloat(CC.SERVICES.reports.data.reportDraft.latitude),
                lng: parseFloat(CC.SERVICES.reports.data.reportDraft.longitude),
              });
            }
            $("#location-field").addClass("required").removeClass("d-none");
          } else {
            $("#location-field").removeClass("required").addClass("d-none");
            $("#confirm-map-button").removeAttr("disabled");
          }

          if (
            CC.SERVICES.reports.data.reportDraft["___is_suggestion_required"] &&
            $("#step-alerttypes-" + subCategoryId).length
          ) {
            $("#modal-alerttypes-" + subCategoryId).modal("show");
          }

          // step show handler
          if (CC.SERVICES.reports.data.reportDraft["___is_location_required"]) {
            $("#step-issue-details").removeClass("d-none");
          }
          // else {
          //   $("#step-contact-details").removeClass("d-none");
          // }

          // update step and report data
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "type_id",
            subCategoryId
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___type_label",
            subTypeLabel
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___is_phone_required",
            CC.SERVICES.reports.data.reportDraft["___is_phone_required"]
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___is_suggestion_required",
            CC.SERVICES.reports.data.reportDraft["___is_suggestion_required"]
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___is_validation_required",
            CC.SERVICES.reports.data.reportDraft["___is_validation_required"]
          );
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_SUGGESTION
              .name
          );
        } else if (eventName == "AFTER_SUGGESTION") {
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_SUGGESTION
              .name;

          // handle UI
          CC.SERVICES.reports.ui.showSteps.showDetails();

          // update step data
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_MAP.name
          );
        } else if (eventName == "AFTER_MAP") {
          // check email
          CC.SERVICES.reports.events.onUpdateCheckEmailValidation();

          // First time here, save the report location
          // un componente gestisce in autonomia la parte di ricerca sulla
          // cartina, popolando in autonomia un campo di localstorage
          // una volta che il processo di selezione della posizione e' terminato
          // (insieme ai suoi dati accessori, come titolo ...) a quel punto
          // peschiamo da localstorage la selezione e la copiamo dentro la variabile
          // sempre in localstorage che contiene tutte le informazioni selezionate per
          // la segnalazione in corso.
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_MAP.name;
          var idx = parseInt(
            JSON.parse(
              localStorage.getItem(CC.CONST.LS_REPORTSADD_RESULT_SELECTED_LABEL)
            )
          );
          var data = null;
          var draft = localStorage.getItem(
            CC.CONST.LS_REPORTSADD_RESULTS_LABEL
          );
          if (draft) {
            data = JSON.parse(draft)[idx];
          }

          // handle data
          if (data) {
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "address",
              data.address
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "latitude",
              data.latitude
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "longitude",
              data.longitude
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "aal1",
              data.aal1
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "aal2",
              data.aal2
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "aal3",
              data.aal3
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "locality",
              data.locality
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "postal_code",
              data.postal_code
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "country",
              data.country
            );
          }
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_CONTACTS.name
          );

          // description and attachments
          if (CC.SERVICES.reports.data.reportDraft.description) {
            $("#description").val(
              CC.SERVICES.reports.data.reportDraft.description
            );
          }
          if (CC.SERVICES.reports.data.reportDraft.attachments) {
            // auto load into `entities_reports_index.html`
          }
          if (CC.SERVICES.reports.data.reportDraft.phone) {
            // resotre attachments ! TODO: manca la gestione del telefono
          }

          // check data before go ahead and prevent next
          var _description = $("#description").val().trim();
          var _title = $("#title").val().trim();
          var _address = "";
          if (data) {
            try {
              _address = data.address || "";
            } catch (error) {}
          } else {
            _address = CC.SERVICES.reports.data.reportDraft.address || "";
          }

          if (
            (CC.SERVICES.reports.data.reportDraft.___is_location_required &&
              (_description.length <= 0 || _address.length <= 0)) ||
            _description.length <= 0
          ) {
            // handle UI
            $("#step-issue-details").removeClass("d-none");

            if (
              _address.length <= 0 &&
              CC.SERVICES.reports.data.reportDraft.___is_location_required
            ) {
              $("#location-field").addClass("has-error");
            }
            if (_description.length <= 0) {
              $("#description-field").addClass("has-error");
            }

            // open modal and return
            $("#requiredModal").modal("show");
            return false;
          }

          // handle title if not compiled
          if (_address.length > 0 && _title.length == 0) {
            var alternativeTitle = _address;
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "title",
              alternativeTitle
            );
            $("#title").val(alternativeTitle);
          }

          // restore stored personal addressbook
          addressbook = CC.SERVICES.reports.fn.isPersonalAddressBookAvailable();
          if (addressbook) {
            $("#user_email-field").val(addressbook["user_email"]);
            $("#user_firstname-field").val(addressbook["user_firstname"]);
            $("#user_lastname-field").val(addressbook["user_lastname"]);

            // resume input checked
            $("#remember_data").attr("checked", "checked");
          }

          // handle UI
          CC.SERVICES.reports.ui.showSteps.showContacts();

          // update step data
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_current",
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_MAP.name
          );
        } else if (eventName == "AFTER_CONTACTS") {
          // local function(s)
          var createNewCredentialAndHandleUI = function (
            userEmail,
            legalAgreementAt,
            entityId,
            showValidationType
          ) {
            /**
             * Example:
             * createNewCredentialAndHandleUI()
             *
             * showValidationType: ["PIN", "SKIP"]
             */
            if (typeof showValidationType === "undefined") {
              showValidationType = "PIN";
            }

            CC.SERVICES.reports.fn.unsignedUserValidationByEmail.create(
              userEmail,
              legalAgreementAt,
              entityId,
              "NEW_REPORT",
              function (res) {
                if (res["response"]["success"] === true) {
                  // success callback, store credential_uuid params
                  var credentials = {
                    email: userEmail,
                    legal_agreement_at: legalAgreementAt,
                    credential_uuid:
                      res["response"]["response"]["credential_uuid"],
                    credential_expired_at:
                      res["response"]["response"]["expired_at"],
                    entity_id: entityId,
                    is_validated: res["response"]["response"]["is_validated"],
                  };

                  // store data into localstorage
                  var strLabel =
                    CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
                      "[[id]]",
                      entityId
                    );
                  var isCredentialAlreadyStored = false;
                  currentCredentials = CC.DATA.store.get(strLabel);
                  if (!currentCredentials) {
                    currentCredentials = new Array();
                  }

                  for (
                    let index = 0;
                    index < currentCredentials.length;
                    index++
                  ) {
                    const cred = currentCredentials[index];
                    if (
                      cred["email"] == credentials["email"] &&
                      cred["credential_uuid"] == credential_uuid
                    ) {
                      isCredentialAlreadyStored = true;
                      currentCredentials[index] = credentials;
                      break;
                    }
                  }
                  if (!isCredentialAlreadyStored) {
                    currentCredentials.push(credentials);
                  }

                  CC.DATA.store.set(
                    CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
                      "[[id]]",
                      entityId
                    ),
                    currentCredentials
                  );

                  // update step data
                  CC.SERVICES.reports.fn.updateDraft(
                    reportClientId,
                    "___step_current",
                    CC.SERVICES.reports.const.reportSteps.eventNames
                      .AFTER_CONTACTS.name
                  );
                  CC.SERVICES.reports.fn.updateDraft(
                    reportClientId,
                    "credential_uuids",
                    credentials["credential_uuid"]
                  );

                  // show pin form
                  if (credentials["is_validated"]) {
                    showValidationType = "SKIP";
                  }
                  CC.SERVICES.reports.ui.showSteps.showValidation(
                    showValidationType
                  );
                } else {
                  CC.UI.me.errors.unsignedUser.creationError();
                }
              },
              function (res) {
                // error callback
                // CC.UI.me.errors.unsignedUser.createCredentialError();
                $("#validation-loader-wrapper").addClass("d-none");
                $("#validation-error-wrapper").removeClass("d-none");
                $("#validation-code-wrapper").addClass("d-none");

                $("#validation-error-wrapper .back-button").on(
                  "click",
                  function () {
                    $("#validation-loader-wrapper").removeClass("d-none");
                    $("#validation-error-wrapper").addClass("d-none");
                  }
                );
              }
            );
          };

          // init check variables
          var checkCount = 0;
          var validCount = 3;
          var isGoNextAvailable = false;
          var isValidationRequired =
            CC.SERVICES.reports.data.reportDraft["___is_validation_required"];
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_CONTACTS
              .name;

          //
          // get data from fields
          //
          var userEmail = $("#user_email-field").val().trim();
          var userFirstName = $("#user_firstname-field").val().trim();
          var userLastName = $("#user_lastname-field").val().trim();
          var userPhone = $("#user_phone-field").val().trim();
          var legalAgreementAt = new Date()
            .toISOString()
            .replace("T", " ")
            .replace("Z", "");

          // user_firstname
          if ($("#user_firstname-field").val().length > 0) {
            $("#user_firstname-wrapper").removeClass("has-error");
            checkCount++;
          } else {
            $("#user_firstname-wrapper").addClass("has-error");
          }

          // user_lastname
          if ($("#user_lastname-field").val().length > 0) {
            $("#user_lastname-wrapper").removeClass("has-error");
            checkCount++;
          } else {
            $("#user_lastname-wrapper").addClass("has-error");
          }

          // user_email
          if ($("#user_email-field").val().length > 0) {
            $("#user_email-wrapper").removeClass("has-error");
            checkCount++;
          } else {
            $("#user_email-wrapper").addClass("has-error");
          }

          // user_phone
          if (CC.SERVICES.reports.data.reportDraft["___is_phone_required"]) {
            validCount++;
            var _checkPhone = $("#user_phone-field").val();
            if (_checkPhone && _checkPhone.length > 0) {
              $("#user_phone-country-wrapper").removeClass("has-error");
              checkCount++;
            } else {
              $("#user_phone-country-wrapper").addClass("has-error");
            }
          }

          // privacy error handler
          if ($("#user_tos_privacy").is(":checked")) {
            $("#user_tos-privacy-wrapper").removeClass("has-error");
          } else {
            $("#user_tos-privacy-wrapper").addClass("has-error");
          }

          // log
          console.log(
            LOGPREFIX +
              "User email|first name|last name: " +
              userEmail +
              "|" +
              userFirstName +
              "|" +
              userLastName
          );

          // store last contact data in requested
          if ($("#remember_data").is(":checked")) {
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_email",
              userEmail
            );
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_firstname",
              userFirstName
            );
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_lastname",
              userLastName
            );

            // log
            console.log(LOGPREFIX + "☑️ remember me active!");
          } else {
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_email",
              ""
            );
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_firstname",
              ""
            );
            CC.SERVICES.reports.fn.updatePersonalAddressBookByField(
              "user_lastname",
              ""
            );

            // log
            console.log(
              LOGPREFIX +
                "🗑️ remember me deactive, delete saved credential into addressbook"
            );
          }

          // Last check before go!
          if (
            $("#user_tos_privacy").is(":checked") &&
            validCount == checkCount
          ) {
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "user_email",
              userEmail
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "user_firstname",
              userFirstName
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "user_lastname",
              userLastName
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "user_tos_privacy",
              legalAgreementAt
            );
            CC.SERVICES.reports.fn.updateDraft(
              reportClientId,
              "phone",
              userPhone
            );

            isGoNextAvailable = true;
          } else {
            $("#requiredModal").modal("show");
          }

          // log
          // isValidationRequired = false;
          console.log(LOGPREFIX + "🟪 isGoNextAvailable? " + isGoNextAvailable);
          console.log(
            LOGPREFIX + "🟧 isValidationRequired? " + isValidationRequired
          );

          if (isGoNextAvailable) {
            // handle UI
            $("#step-validate-unsigned-user").removeClass("d-none");

            if (!isValidationRequired) {
              // This entity + type_id do not require validation
              createNewCredentialAndHandleUI(
                userEmail,
                legalAgreementAt,
                entityId,
                "SKIP"
              );

              // update step data
              CC.SERVICES.reports.fn.updateDraft(
                reportClientId,
                "___step_current",
                CC.SERVICES.reports.const.reportSteps.eventNames
                  .AFTER_VALIDATION.name
              );
            } else {
              // **********************************************************
              // ********************** switch point **********************
              // **********************************************************
              var credss = CC.DATA.store.get(
                CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
                  "[[id]]",
                  entityId
                )
              );
              if (credss && credss.length > 0) {
                var email = null;
                var credential_uuid = null;

                // cerchiamo le credenziali ...
                for (let index = 0; index < credss.length; index++) {
                  const creds = credss[index];

                  if (creds) {
                    if (typeof creds === "object" && !Array.isArray(creds)) {
                      var _email = creds["email"];
                      var _credential_uuid = creds["credential_uuid"];
                      if (_email == userEmail && _credential_uuid) {
                        email = _email;
                        credential_uuid = _credential_uuid;
                        break;
                      }
                    }
                  }
                }

                // se le abbiamo, prima verifichiamo che siano valide, se no le ri-creiamo ...
                if (email && credential_uuid) {
                  // init variable(s)
                  var credsFoundAndCheck = null;

                  // declare method(s)
                  var waitUntilCheckFinish = function () {
                    console.log(
                      "Checking credsFoundAndCheck ... " + credsFoundAndCheck
                    );
                    if (credsFoundAndCheck === null) {
                      setTimeout(waitUntilCheckFinish, 100);
                      return;
                    }
                  };

                  // log
                  console.log(
                    "🔐 - <creds> found: cred uuid:" +
                      credential_uuid +
                      ", email: " +
                      email +
                      ". request sent to check validation ..."
                  );

                  // check credentials
                  CC.SERVICES.reports.fn.unsignedUserValidationByEmail.check(
                    credential_uuid,
                    function (data) {
                      // init variables
                      var isCheckValid = false;
                      var returnCode = null;

                      try {
                        isCheckValid = data["response"]["response"]["is_valid"];
                        returnCode =
                          data["response"]["response"]["return_code"];
                      } catch (error) {}

                      if (isCheckValid == true) {
                        // log
                        console.log(
                          "🔐 - <creds> ✅ still valid: " + returnCode
                        );

                        // set up
                        CC.SERVICES.reports.fn.updateDraft(
                          reportClientId,
                          "credential_uuids",
                          credential_uuid
                        );
                        credsFoundAndCheck = true;

                        // handle ui, go next
                        CC.SERVICES.reports.ui.showSteps.showSummaryStep();
                      } else {
                        // log
                        console.log(
                          "🔐 - <creds> 🏴󠁤󠁥󠁮󠁩󠁿 no more valid or not valid yet, return_code: " +
                            returnCode
                        );

                        // set the flag
                        createNewCredentialAndHandleUI(
                          userEmail,
                          legalAgreementAt,
                          entityId
                        );
                        credsFoundAndCheck = false;
                      }
                    },
                    function () {
                      // log
                      console.log(
                        "🔐 - <creds> 🏴󠁤󠁥󠁮󠁩󠁿 no more valid, create new one!"
                      );

                      // set the flag
                      createNewCredentialAndHandleUI(
                        userEmail,
                        legalAgreementAt,
                        entityId
                      );
                      credsFoundAndCheck = false;
                    }
                  );
                } else {
                  console.log(
                    "🔐 - <creds> 🏴󠁤󠁥󠁮󠁩󠁿 not found on localstorage, create new one!"
                  );
                  createNewCredentialAndHandleUI(
                    userEmail,
                    legalAgreementAt,
                    entityId
                  );
                }
              } else {
                console.log("🔐 - no <creds> available, create new one!");
                createNewCredentialAndHandleUI(
                  userEmail,
                  legalAgreementAt,
                  entityId
                );
              }
            }
          } else {
            // not valid submission, reset and flag
            $("#step-contact-details").removeClass("d-none");
          }
        } else if (eventName == "AFTER_VALIDATION") {
          // init variables
          var digits = new Array();
          var strLabel =
            CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
              "[[id]]",
              entityId
            );
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.AFTER_VALIDATION
              .name;
          var userEmail = $("#user_email-field").val();
          var credentials = {
            credential: null,
            credential_uuid: null,
            credential_type: "EMAIL",
            pin: null,
          };

          // get digits
          var digit_1 = $("#input_digits-1").val();
          var digit_2 = $("#input_digits-2").val();
          var digit_3 = $("#input_digits-3").val();
          var digit_4 = $("#input_digits-4").val();
          var digit_5 = $("#input_digits-5").val();
          var digit_6 = $("#input_digits-6").val();

          // store digits into array
          digits.push(digit_1);
          digits.push(digit_2);
          digits.push(digit_3);
          digits.push(digit_4);
          digits.push(digit_5);
          digits.push(digit_6);
          digits = digits.join("").toString();
          credentials["pin"] = digits;

          // prepare data to validate ...
          var credss = CC.DATA.store.get(
            CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
              "[[id]]",
              entityId
            )
          );
          if (credss && credss.length > 0) {
            for (let index = 0; index < credss.length; index++) {
              const creds = credss[index];

              // log
              console.log("0️⃣ - digits: " + digits + " ...");

              if (creds) {
                if (typeof creds === "object" && !Array.isArray(creds)) {
                  email = creds["email"];
                  credential_uuid = creds["credential_uuid"];

                  if (email == userEmail) {
                    credentials["credential"] = email;
                    credentials["credential_uuid"] = credential_uuid;
                    credentials["pin"] = digits;

                    // log
                    console.log(
                      "0️⃣ - digits, email and credential stored into credentials array ..."
                    );

                    break;
                  }
                }
              }
            }
          } else {
            CC.SERVICES.reports.events.onClickBack();
          }

          // handle UI
          $("#validate-user-button").attr("disabled", "disabled");

          // send data
          CC.SERVICES.reports.fn.unsignedUserValidationByEmail.validate(
            credentials["credential"],
            credentials["credential_uuid"],
            credentials["pin"],
            function (response) {
              if (response["is_valid"] === true) {
                // update draft after validation
                CC.SERVICES.reports.fn.updateDraft(
                  reportClientId,
                  "credential_uuids",
                  response["uuid"]
                );

                // handle UI, simulate an onClickNext events
                CC.SERVICES.reports.events.onClickNext(
                  CC.SERVICES.reports.const.reportSteps.eventNames.BEFORE_SEND
                    .name
                );
              } else {
                CC.SERVICES.reports.ui.showSteps.showValidation("PIN");
              }
            },
            function () {
              CC.UI.me.errors.genericNetworkError();
            }
          );
        } else if (eventName == "BEFORE_SEND") {
          var currentEventName =
            CC.SERVICES.reports.const.reportSteps.eventNames.BEFORE_SEND.name;
          CC.SERVICES.reports.ui.showSteps.showSummaryStep();
        }

        // reset scroll
        document.getElementById("new-report-wrapper").scrollTop = 0;

        // store progress pointer
        if (currentEventName) {
          CC.SERVICES.reports.fn.updateDraft(
            reportClientId,
            "___step_last_complete",
            currentEventName
          );
        } else {
          console.error("No currentEventName setted");
        }
      },
      onClickDeleteDraft: function (localStorageId) {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onClickDeleteDraft();
         */
        console.log("CC.SERVICES.reports.ui.onClickDeleteDraft() called");

        CC.SERVICES.reports.ui.confirmDraftDeleteModal.open(localStorageId);
      },
      onClickResetReport: function (entityId, hasReload) {
        for (var key in CC.SERVICES.reports.data.reportDraft) {
          // reset js variable
          CC.SERVICES.reports.data.reportDraft[key] = null;
        }

        if (typeof hasReload === "undefined") {
          var hasReload = true;
        }

        if (typeof entityId === "undefined") {
          // nothing to do
        } else {
          var storeLabel = CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
            "[[id]]",
            entityId
          );
          var storeLabelPosition1 = CC.CONST.LS_REPORTSADD_RESULTS_LABEL;
          var storeLabelPosition2 =
            CC.CONST.LS_REPORTSADD_RESULT_SELECTED_LABEL;
          CC.DATA.store.remove(storeLabel);
          CC.DATA.store.remove(storeLabelPosition1);
          CC.DATA.store.remove(storeLabelPosition2);
        }

        // debug print
        console.debug(CC.SERVICES.reports.data.reportDraft, "reportDraft");

        if (hasReload) {
          window.location.href = window.location.href;
        }
      },
      onClickSendUnsignedReport: function (successCallback, errorCallback) {
        /**
         * ===============================
         * ===== Available Post DATA =====
         * ===============================
         * - is_debug
         * - entity_id
         * - device_id
         * - type_id
         * - description
         * - user_firstname
         * - user_lastname
         * - user_email
         * - user_phone
         * - user_report_title
         * - created_by_user_type_id
         * - latitude
         * - longitude
         * - address
         * - locality
         * - postal_code
         * - country
         * - credential_uuids
         * - session_uuid
         * - legal_agreement_at
         * - aal_1
         * - aal_2
         * - aal_3
         * - attachment_1
         * - attachment_2
         * - attachment_3
         * - is_credential_validation_enabled (new)
         *
         * ================================
         * ======= Available HEADER =======
         * ================================
         * - cc-client-uuid
         * - app-lang
         * - user-agent
         * - cc-endpoint-auth-token
         */
        console.debug(
          arguments,
          "CC.SERVICES.reports.events.onClickSendUnsignedReport()"
        );

        // update report draft
        // CC.SERVICES.reports.fn.updateDraft("description", description);
        // CC.SERVICES.reports.fn.updateDraft("attachments", attachments);
        // if ($("#phone").length) {
        //   CC.SERVICES.reports.fn.updateDraft("phone", $("#phone").val());
        // }

        // get report draft
        var clientUUIDData =
          CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();
        var reportDraft = CC.SERVICES.reports.data.reportDraft;

        reportDraft["client_uuid"] = clientUUIDData["client_uuid"];

        // send report data
        var url = "/rest/private/reports/unsigned_send";
        $.ajax({
          type: "POST",
          url: url,
          data: JSON.stringify(reportDraft),
          contentType: "application/json",
          dataType: "json",
        })
          .done(function (data) {
            successCallback.call(this, data);
          })
          .fail(function (data) {
            errorCallback.call(this, data);
          });
      },
      onUpdateCheckEmailFormat: function (email) {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onUpdateCheckEmailFormat()
         *
         */
        var regexp =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(String(email).toLowerCase());
      },
      onUpdateCheckEmailValidation: function (entityId) {
        /**
         *
         * Example:
         * CC.SERVICES.reports.events.onUpdateCheckEmailValidation()
         *
         */
        var disableAllValidationTags = function () {
          $(".validation-tag-wrapper").find(".loader").addClass("d-none");
          $(".validation-tag-wrapper").find(".verified").addClass("d-none");
          $(".validation-tag-wrapper").find(".spid").addClass("d-none");
        };

        var enableLoader = function () {
          $(".validation-tag-wrapper").find(".loader").removeClass("d-none");
        };

        // handle UI
        enableLoader();

        // fetch data
        var email = $("#user_email-field").val();
        var isValid =
          CC.SERVICES.reports.events.onUpdateCheckEmailFormat(email);
        var credentialUUIDDatas = CC.DATA.store.get(
          CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_VALIDATIONS.replace(
            "[[id]]",
            entityId
          )
        );

        if (credentialUUIDDatas && credentialUUIDDatas.length > 0) {
          console.log(
            "🔓 - onUpdateCheckEmailValidation(): looking for current user_email: " +
              email +
              "..."
          );
          for (let index = 0; index < credentialUUIDDatas.length; index++) {
            const credentialUUIDData = credentialUUIDDatas[index];

            // check and show
            if (isValid && credentialUUIDData["email"] == email) {
              // log
              console.log(
                "🔓 - onUpdateCheckEmailValidation(): ✅ is well formatted? " +
                  isValid +
                  ", current user_email: " +
                  email +
                  ", credentialUUID email: " +
                  credentialUUIDData["email"]
              );

              CC.SERVICES.reports.fn.unsignedUserValidationByEmail.check(
                credentialUUIDData["credential_uuid"],
                function (data) {
                  var isCheckValid = false;
                  var returnCode = null;
                  try {
                    isCheckValid = data["response"]["response"]["is_valid"];
                    returnCode = data["response"]["response"]["return_code"];
                  } catch (error) {}

                  if (isCheckValid) {
                    // log
                    console.log(
                      "🔓 - onUpdateCheckEmailValidation(): ✅  check response: " +
                        returnCode
                    );

                    // handle UI
                    disableAllValidationTags();
                    $(".validation-tag-wrapper")
                      .find(".verified")
                      .removeClass("d-none");
                  } else {
                    disableAllValidationTags();
                    // log
                    console.log(
                      "🔐 - <creds> 🏴󠁤󠁥󠁮󠁩󠁿 no more valid or not valid yet, response: " +
                        returnCode
                    );
                  }
                },
                function (errorData) {
                  console.error("An error occurred during check credential :(");
                  disableAllValidationTags();
                }
              );
            } else {
              // log
              console.log(
                "🔓 - onUpdateCheckEmailValidation(): ⏭️ is well formatted? " +
                  isValid +
                  ", current user_email: " +
                  email +
                  ", credentialUUID email: " +
                  credentialUUIDData["email"]
              );

              // handle UI
              disableAllValidationTags();
            }
          }
        } else {
          disableAllValidationTags();
          console.log(
            "🔓 - onUpdateCheckEmailValidation(): no credentials available, go next!"
          );
        }
      },
    },
    fn: {
      getAvailableDrafts: function (entityId) {
        /**
         * This method read and find all available draft(s) for a specific
         * entityId, clean all not valid draft and return ordered list of draft.
         * At the top of the list the most new draft, the oldiest at the end.
         */
        var availableDrafts = new Array();
        var createdAtDrafts = new Array();
        var orderedDraftKeys = new Array();
        var lsCurrentReportLabel = CC.CONST.LS_ME_CURRENT_REPORT_DRAFT_ID;
        var lsLabel = CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
          "[[id]]",
          entityId
        );

        var currentReportId = JSON.parse(
          localStorage.getItem(lsCurrentReportLabel)
        );
        console.log(
          "getAvailableDrafts(): Looking for draft(s) in this entity ..."
        );

        for (var key in localStorage) {
          console.log("Analyzing " + key + "...");
          if (key.startsWith(lsLabel)) {
            var reportId = key.split("_").reverse()[0];
            if (reportId.toString() !== currentReportId.toString()) {
              var obj = JSON.parse(localStorage.getItem(key));

              // check if the draft is valid
              if (
                obj["___created_at"] &&
                obj["___main_type_id"] &&
                obj["___main_type_label"]
              ) {
                availableDrafts.push(key);
                createdAtDrafts.push(obj["___created_at"]);
                console.log("getAvailableDrafts(): --> find: " + key + "...");
              } else {
                localStorage.removeItem(key);
                console.log(
                  "getAvailableDrafts(): --> delete not complete draft: " +
                    key +
                    "..."
                );
                console.log(
                  "getAvailableDrafts(): --> delete " +
                    obj["___created_at"] +
                    " + " +
                    obj["___main_type_id"]
                );
              }
            }
          }
        }

        var createdAtDraftsOrdered = createdAtDrafts.sort();

        for (var i = createdAtDraftsOrdered.length - 1; i >= 0; i--) {
          for (var y in availableDrafts) {
            var obj = JSON.parse(localStorage.getItem(availableDrafts[y]));
            if (obj["___created_at"] == createdAtDraftsOrdered[i]) {
              orderedDraftKeys.push({
                id: availableDrafts[y],
                obj: obj,
              });
              break;
            }
          }
        }

        return orderedDraftKeys;
      },
      isRestoreCalled: function () {
        // TODO: not implemented yet
        var paramString = document.URL.split("?")[1];
        var queryString = new URLSearchParams(paramString);
        for (let pair of queryString.entries()) {
          if (pair[0] == "id" && pair[1].length > 0) {
            var entityId = $("#current_entity_id").val();
            var storeLabel =
              CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
                "[[id]]",
                entityId
              ) +
              "_" +
              pair[1];

            if (localStorage.getItem(storeLabel)) {
              return storeLabel;
            }
          }
        }
        return false;
      },
      updateDraft: function (id, field, value) {
        // set the value in the variable
        CC.SERVICES.reports.data.reportDraft[field] = value;

        // store the value
        var entityId = $("#current_entity_id").val();
        var storeLabel =
          CC.CONST.LS_ME_ENTITY_SERVICE_REPORT_DRAFT.replace(
            "[[id]]",
            entityId
          ) +
          "_" +
          id;
        CC.DATA.store.set(storeLabel, CC.SERVICES.reports.data.reportDraft);

        // debug print
        console.log("[reportDraft] field: <" + field + ">, value: " + value);

        // Debug step while change ...
        // if (field == "___step_current") {
        //   alert("new step current value: " +  value);
        // }
      },
      isPersonalAddressBookAvailable: function () {
        var lsLabel = CC.CONST.LS_ME_PERSONAL_ADDRESSBOOK;
        var lsData = CC.DATA.store.get(lsLabel);

        if (lsData) {
          return lsData;
        } else {
          return false;
        }
      },
      updatePersonalAddressBookByField: function (field, value) {
        var lsLabel = CC.CONST.LS_ME_PERSONAL_ADDRESSBOOK;
        var lsData = CC.DATA.store.get(lsLabel);

        if (!lsData) {
          lsData = {};
        }
        lsData[field] = value;
        CC.DATA.store.set(lsLabel, lsData);
      },
      unsignedUserReports: {
        import: function (
          userEmail,
          credentialUUID,
          successCallback,
          errorCallback
        ) {
          /**
           * Example:
           * CC.SERVICES.reports.fn.unsignedUserReports.import();
           */
          var url = "/rest/private/reports/bulk_import";
          var clientUUIDData =
            CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();

          // TODO: qui ho mockato credenziali
          var data = {
            user_email: userEmail,
            credential_uuid: credentialUUID,
            client_uuid: clientUUIDData["client_uuid"],
          };

          console.log("⬇️ - New import request ...");

          // send report data
          $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
          })
            .done(function (data) {
              console.log("⬇️ - Success response: " + data);
              successCallback.call(this, data);
            })
            .fail(function (data) {
              console.log("⬇️ - ERROR during import reports :(");
              errorCallback.call(this, data);
            });
        },
      },
      unsignedUserValidation: {
        initClientUUID: function () {
          /**
           * Example:
           * CC.SERVICES.reports.fn.unsignedUserValidation.initClientUUID();
           */
          var currentClientUUID = CC.DATA.store.get(
            CC.CONST.LS_ME_CURRENT_CLIENT_CREDENTIALS
          );
          if (!currentClientUUID) {
            var uuid = "";
            try {
              uuid = crypto.randomUUID();
            } catch (error) {
              if (error instanceof TypeError) {
                uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
                  /[018]/g,
                  (c) =>
                    (
                      c ^
                      (crypto.getRandomValues(new Uint8Array(1))[0] &
                        (15 >> (c / 4)))
                    ).toString(16)
                );
              }
            }
            var now = new Date()
              .toISOString()
              .replace("T", " ")
              .replace("Z", "");
            currentClientUUID = {
              client_uuid: uuid,
              created_at: now,
            };
            CC.DATA.store.set(
              CC.CONST.LS_ME_CURRENT_CLIENT_CREDENTIALS,
              currentClientUUID
            );
          }
          return currentClientUUID;
        },
        getClientUUID: function () {
          /**
           * Example:
           * CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();
           */
          var currentClientUUID = CC.DATA.store.get(
            CC.CONST.LS_ME_CURRENT_CLIENT_CREDENTIALS
          );
          if (!currentClientUUID) {
            currentClientUUID =
              CC.SERVICES.reports.fn.unsignedUserValidation.initClientUUID();
          }
          return currentClientUUID;
        },
      },
      unsignedUserValidationByEmail: {
        create: function (
          email,
          legalAgreementAt,
          entityId,
          reason,
          successCallback,
          errorCallback
        ) {
          /**
           * Questo endpoint crea una richiesta di verifica di credenziale, per generare
           * un pin di sicurezza.
           *
           * Per farlo viene inviato:
           * - email:                 specificata nel form contatto
           * - client_uuid:           generato dal client autonomamente
           * - legal_agreement_at:    che è il momento in cui si accettano le condizioni
           * - entity_id:             l'id dell'ente a cui sta inviando il ticket
           * - reason:                valid reason(s) ["NEW_REPORT", "GET_REPORTS"]
           *
           * Example:
           *  CC.SERVICES.reports.fn.unsignedUserValidationByEmail.create()
           */
          var url = "/rest/private/reports/unsigned_user/validation/create";
          var clientUUIDData =
            CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();

          var data = {
            email: email,
            client_uuid: clientUUIDData["client_uuid"],
            entity_id: entityId,
            legal_agreement_at: legalAgreementAt,
            entity_id: parseInt(entityId),
            reason: reason,
          };

          console.log("🔐 - Create new credential request ...");

          // send report data
          $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
          })
            .done(function (data) {
              console.log("🔐 - New credential available, code sent!");
              successCallback.call(this, data);
            })
            .fail(function (data) {
              console.log("🔐 - ERROR during create credential :(");
              errorCallback.call(this, data);
            });
        },
        check: function (credential_uuid, successCallback, errorCallback) {
          /**
           *
           * Questo endpoint verifica se e' scaduta la richiesta di validazione della email.
           *
           * CC.SERVICES.reports.fn.unsignedUserValidationByEmail.check()
           */
          var isValid = false;
          var url = "/rest/private/reports/unsigned_user/validation/check";
          var clientUUIDData =
            CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();

          var data = {
            // "user_email": email,
            credential_uuids: credential_uuid,
            client_uuid: clientUUIDData["client_uuid"],
          };

          $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
          })
            .done(function (data) {
              successCallback.call(this, data);
            })
            .fail(function (data) {
              errorCallback.call(this, data);
            });
        },
        validate: function (
          email,
          credential_uuid,
          pin,
          successCallback,
          errorCallback
        ) {
          /**
           *
           * Questo endpoint valida un pin e una credential_uuid.
           *
           * CC.SERVICES.reports.fn.unsignedUserValidationByEmail.validate()
           */
          var url = "/rest/private/reports/unsigned_user/validation/validate";
          var clientUUIDData =
            CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();

          // prepare POST data
          var data = {
            credentials: [
              {
                credential: email,
                credential_uuid: credential_uuid,
                credential_type: "EMAIL",
                pin: pin.toString(),
              },
            ],
            client_uuid: clientUUIDData["client_uuid"],
          };

          // reset error UI
          CC.UI.me.errors.afterValidationError.reset();

          // send report data
          $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
          })
            .done(function (data) {
              var response = data["response"];

              if (response["success"] === true) {
                response = response["response"][0];
                if (response["return_code"] === "SUCCESS") {
                  console.log("✅ Code valid");
                } else if (response["return_code"] === "CODE_NOT_VALID") {
                  console.log("❌ Code not valid");
                  CC.UI.me.errors.afterValidationError.pinNotValid();
                } else if (response["return_code"] === "EXPIRED_RENEWED_SENT") {
                  console.log("❌ Code expired, sent again");
                  $("#validation-error-expired-resent").removeClass("d-none");
                } else if (response["return_code"] === "NOT_VALID") {
                  console.log("❌ Request not valid");
                  $("#validation-error-unknown").removeClass("d-none");
                } else if (response["return_code"] === "EXPIRED") {
                  console.log("❌ Code expired");
                  $("#validation-error-expired").removeClass("d-none");
                } else {
                  $("#validation-error-unknown").removeClass("d-none");
                  console.log("❌ Unknown error");
                }
              } else {
                console.log(
                  "❌ Response error: " + JSON.stringify(response["error"])
                );

                if (pin.length != 6) {
                  $("#validation-error-empty").removeClass("d-none");
                } else {
                  $("#validation-error-dynamic").removeClass("d-none");
                  $("#validation-error-dynamic-text").text(
                    response["error"]["description"]
                  );
                }
              }
              successCallback.call(this, response);
            })
            .fail(function (data) {
              console.debug(data, "--> fail validate");
              errorCallback.call(this, data);
            });
        },
        reports_available_count: function (
          email,
          successCallback,
          errorCallback
        ) {
          /**
           *
           * Example:
           * CC.SERVICES.reports.fn.unsignedUserValidationByEmail.reports_available()
           *
           */
          var url = "/rest/private/reports/check_bulk_import";
          var clientUUIDData =
            CC.SERVICES.reports.fn.unsignedUserValidation.getClientUUID();

          // prepare POST data
          var data = {
            user_email: email,
            credential_uuid: clientUUIDData["client_uuid"],
          };

          // send report data
          $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
          })
            .done(function (data) {
              successCallback.call(this, data);
            })
            .fail(function (err) {
              errorCallback.call(this, err);
            });
        },
      },
    },
  },
  generic: {
    countdown: function (startTime, divIdOrClass, finishCallback) {
      /**
       *
       * Example
       * CC.SERVICES.generic.countdown("5:00", ".countdown");
       */
      var timer2 = startTime;
      var interval = setInterval(function () {
        var timer = timer2.split(":");

        //by parsing integer, I avoid all extra string processing
        var minutes = parseInt(timer[0], 10);
        var seconds = parseInt(timer[1], 10);
        --seconds;
        minutes = seconds < 0 ? --minutes : minutes;
        if (minutes < 0) clearInterval(interval);
        seconds = seconds < 0 ? 59 : seconds;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        if (minutes < 0) {
          clearInterval(interval);
          if (finishCallback) {
            finishCallback.call(this);
          }
        } else {
          $(divIdOrClass).html(minutes + ":" + seconds);
          timer2 = minutes + ":" + seconds;
        }
      }, 1000);
    },
  },
};

/**
 * Ajax setup, used to get the CSRF Token from the page and
 * place it in the ajax request.
 */
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (
      !/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) &&
      !this.crossDomain
    ) {
      var csrftoken = $("meta[name=csrf-token]").attr("content");
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
  },
});
